* {
  outline: 0;
  box-sizing: border-box;
  text-decoration: none !important;
  outline: none !important;
}

@font-face {
  font-family: icomoon;
  src: local('icomoon'), url(./assets/fonts/icomoon.eot?5oufxe);
  src: local('icomoon'),
    url(./assets/fonts/icomoon.eot?5oufxe#iefix) format('embedded-opentype'),
    url(./assets/fonts/icomoon.ttf?5oufxe) format('truetype'),
    url(./assets/fonts/icomoon.woff?5oufxe) format('woff'),
    url(./assets/fonts/icomoon.svg?5oufxe#icomoon) format('svg');
  font-weight: normal;

  font-style: normal;
  font-display: block;
}

body {
  font-family: Montserrat, sans-serif;
  background-color: #f5f7f9;
  color: #777;
  position: relative;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #777 !important;
  -webkit-text-fill-size: 18px !important;
}

.form-control {
  box-shadow: none !important;
}

input::placeholder {
  -webkit-text-fill-color: #a4a4a4 !important;
}
@media (max-width: 1199px) {
  .row-btn > div + div {
    margin-top: 20px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
